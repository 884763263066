import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./aboutme.css";

function AboutMeCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="CarouselDems"
    >
      <Carousel.Item>
        <h3>I am a Front End Developer based in Stockton California.</h3>
        <p>
          I am a Junior Web Developer with experience in HTML, CSS, JavaScript,
          and React. I'm passionate about creating innovative and user-friendly
          applications that can help people solve problems and connect with each
          other. I am Currently working at CodeStack Academy as a Junior Developer 
          / Teacher's Assistant for the upcoming Class.
        </p>
      </Carousel.Item>
      <Carousel.Item>
        <h3>Currently Working at CodeStack Academy</h3>
        <p>
          I've been a martial arts
          enthusiast for over 14 years, teaching and spreading my knowledge for
          the past 8. Recently, I've found a new passion in web development,
          which has become an exciting new chapter in my life. I've been
          attending Code Stack Academy since 2022, and I'm always eager to learn
          and explore new ideas, and I find that web development offers endless
          opportunities for growth and creativity.
        </p>
      </Carousel.Item>
    </Carousel>
  );
}

export default AboutMeCarousel;
