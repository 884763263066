import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

export default function Resume() {
  const [lgShow, setLgShow] = useState(false);
  const resumepdf = require('../assets/Resume.pdf')


  return (
    <>
        <i onClick={() => setLgShow(true)}  title='Resume' className='bx bx-spreadsheet bx-lg resume'></i>
        <Modal
  size="lg"
  show={lgShow}
  onHide={() => setLgShow(false)}
  aria-labelledby="example-modal-sizes-title-lg"
>

      <iframe src={resumepdf} className='resumeDoc'></iframe>

</Modal>
    </>
  );
}
