import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import aboutMePic from '../assets/headshot-copy.png'
import Resume from './ResumeModal';
import { SiMicrosoftazure, SiSlack, SiJirasoftware, SiFigma, SiGithub, SiNotion } from 'react-icons/si';
import AboutMeCarousel from './AboutMeCarousel';


export default function HomePage() {
  const SQL = require('../assets/image.png');
  const CSharp = require('../assets/csharp.png');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 800);
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  })

  return (
    <section className='container-fluid home'>
      <Row>
      <Col lg={6} className='d-flex justify-content-center order-lg-last'>
        <div className='about-img'>
            <img src={aboutMePic} alt='Profile Picture'/>
            <span className='circle-spin'></span>
        </div>
        {(isMobile) && (<div className='contactmeDiv'>
          <div><h2 style={{fontWeight: 600}}>Connect With Me</h2></div>
          <div className='d-flex justify-content-evenly connectWithMeIcons'>
          <a href='https://www.linkedin.com/in/isaiah-ferguson/' target='_blank'><i className='bx  bxl-linkedin-square bx-lg'></i></a>
          <Resume/>
          <a href='https://github.com/Isaiah-Ferguson' target='_blank'><i className='bx bxl-github bx-lg'alt='GitHub'></i></a>
          </div>
          </div>)}
 

        </Col>
        <Col lg={6}>
        <div className='home-content'>
            <h1>Hi, I'm <span>Isaiah</span></h1>
            <div className='text-animate'>
                <h3>Junior Developer</h3>
            </div>
            <AboutMeCarousel/>
            <h2 className='skills'>
             <span>Skills</span>
        </h2>
        <Row>
          <Col lg={4} md={4} sm={4}>
                      
          <h2>Libraries</h2>
            <i title='React' className='bx bxl-react bx-lg' alt='React'></i>
          </Col>
          <Col>
          <h2>Langauges</h2>
            <i title='Javascript' className='bx bxl-javascript bx-lg' alt='JavaSCript'></i>
            <i title='HTML5' className='bx bxl-html5 bx-lg' alt='HTML5'></i>
            <i title='TypeScript' className='bx bxl-typescript bx-lg' alt='TypeScript'></i>
            <i><img title='SQL' className='icons' src={SQL} alt='SQL'/></i>

            <i><img title='C#' className='icons' src={CSharp} alt='C#'/></i>
          </Col>
        </Row>
        <Row>
          <Col  lg={4} md={4} sm={4}>
          <div className='frameworks'>
        <h2>FrameWorks</h2>
            <i className='bx bxl-bootstrap bx-lg' alt='BootStrap'></i>
            <i className='bx bxl-tailwind-css bx-lg' alt='Tailwind'></i>
        </div>
          </Col>
          <Col>
          <div  className='frameworks'>
          <h2>Productivity</h2>
          {(isMobile) && (<><SiMicrosoftazure title='Azure' className='ReactIcons' size='25' />
          <SiSlack title='Slack' className='ReactIcons' style={{marginLeft: 7}} size='25' />
          <SiJirasoftware title='Jira' className='ReactIcons' style={{marginLeft: 7}} size='25' />
          <SiFigma title='Figma' className='ReactIcons' style={{marginLeft: 7}} size='25' />
          <SiGithub title='Git-Hub' className='ReactIcons' style={{marginLeft: 7}} size='25' />
          <SiNotion title='Notion' className='ReactIcons' style={{marginLeft: 7}} size='25' />
          {/* <i title='VS-Code' className='bx bxl-visual-studio bx-lg' alt='VS Code'></i> */}
          </>
         )}
          {(!isMobile) && (<>
          <SiMicrosoftazure title='Azure' className='ReactIcons' size='40' />
          <SiSlack title='Slack' className='ReactIcons' style={{marginLeft: 7}} size='40' />
          <SiJirasoftware title='Jira' className='ReactIcons' style={{marginLeft: 7}} size='40' />
          <SiFigma title='Figma' className='ReactIcons' style={{marginLeft: 7}} size='40' />
          <SiGithub title='Git-Hub' className='ReactIcons' style={{marginLeft: 7}} size='40' />
          <SiNotion title='Notion' className='ReactIcons' style={{marginLeft: 7}} size='40' />
          {/* <i title='VS-Code' className='bx bxl-visual-studio bx-lg' alt='VS Code'></i> */}
          </>)}
          </div>

          </Col>
        </Row>
        </div>
        </Col>
      </Row>
    </section>
  )
}
