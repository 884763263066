import React from 'react'
import { useState, useEffect } from 'react';
export default function ScrollButton() {

      const [showButton, setShowButton] = useState(false);
      useEffect(() => {
        function handleScroll() {
          if (window.pageYOffset > 200) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      function handleClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    
      return (
        <button
        className='scrollBtn'
          onClick={handleClick}
          style={{
            display: showButton ? 'block' : 'none',
          }}
        >
          <i className='bx bx-chevrons-up' >Up</i>
        </button>

      );
    }