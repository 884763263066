import React from 'react'
import { Col, Row } from 'react-bootstrap'

export default function Footer() {
  return (
    <div className='footer'>
      
        <div className='container-fluid'>
        <Row><h1 style={{marginTop: '50px'}} className='heading'><span>Unity</span> Project</h1>
          <Col lg={12} className='d-flex justify-content-center' style={{marginTop: '50px'}}><iframe src="https://i.simmer.io/@Flysaiah/hajime-no-ippo-trivia" className='Game'></iframe></Col>
            <Col lg={12} md={12} className='footerText 'style={{marginTop: '50px'}}>
            <h1 className='d-flex justify-content-center'>Contact Me</h1>
            <p className='d-flex justify-content-center'><i className='bx bx-envelope' > IFerguson@codestack.co</i></p>
            <p className='d-flex justify-content-center'><i className='bx bx-current-location' > Stockton, California</i></p>
            <p className='d-flex justify-content-center'><i className='bx bx-phone' > (209)-594-5661</i></p>
            </Col>
        </Row>
        </div>

    </div>
  )
}
