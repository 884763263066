import { useState, useEffect } from 'react'
import React from 'react'
import Resume from './ResumeModal'

export default function Sidebar() {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth > 800);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      })

  return (
    <>
{(isDesktop) && (    <div className='ContactMeSTicky'>
<div className='virt'>
<a href='https://www.linkedin.com/in/isaiah-ferguson/' without rel="noreferrer" title='LinkedIn' target='_blank'><i className='bx  bxl-linkedin-square bx-lg'></i></a>
<Resume/>
<a href='https://github.com/Isaiah-Ferguson' without rel="noreferrer" title='GitHub' target='_blank'><i className='bx bxl-github bx-lg'></i></a>
</div>
</div>)}
</>
  )
}
