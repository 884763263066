import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';

function NavScrollExample() {
  return (
    <Navbar className='header' expand="md">
      <Container fluid>
        <Navbar.Brand href="#" className='Logo'>Isaiah.</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" >
          <Nav
            className="me-auto my-2 my-lg-0 "
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Form className='NavDiv'>
            <Link  to="projects" smooth={true} duration={500} className='navfont'>Projects</Link>
            <Link to="about-me" smooth={true} duration={500}  className='navfont'>Unity</Link>
            <a className='navfont' href={'https://calendly.com/crestice/15-20-minutes-screen-interview'} target='_blank'>Connect With Me</a>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;