import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NewProjectCard from './NewProjectCard'
import { SiCsharp, SiMysql } from 'react-icons/si';

export default function ProjectPage() {
  const [thePath, setThePath] = useState('The Path')
  const [thePathDesc, setThePathDesc] = useState('The Path is a social media app focused on Brazilian Jiu Jitsu. I took on roll of project manager for this site. we took it from concept to completion within 3 months. This app aims to connect BJJ practitioners and enthusiasts, allowing them to share techniques, strategies, and insights on the martial art.')
  const [thePathSite, setThePathSite] = useState('https://thepathpls.vercel.app/');

  const CamaPic = require('../assets/Portfoliosite.png')
  const [teamCama, setTeamCama] = useState('Team Cama Business')
  const [teamCamaDesc, setTeamCamaDesc] = useState('This Site was developed in collaboration with the owner of a martial arts school, using vanilla JavaScript and hosted on Azure. the site is user-friendly and better represents their brand and attracts more customers.');
  const [teamCamaSite, setTeamCamaSite] = useState('https://businesredevelopment.vercel.app');
  const thepathpic = require('../assets/triangle.jpg')
  const pokePic= require('../assets/PokePortfolio.png')
  const [PokiApi, setPokiApi] = useState('Pokemon Api');
  const [pokeDesc, setpokeDesc] = useState('The PokeMon Api project allows its users search for any pokemon it will display their type move set and other information. this site helped me hone my high order function skills aswell as build a foundation for api calls.');
  const [pokeSite, setPokeSite] = useState('https://pokemonwebapp-seven.vercel.app');

  const react = <i className='bx bxl-react bx-lg'></i>;
  const Bootstrap =  <i className='bx bxl-bootstrap bx-lg'></i>;
  const typescript = <i className='bx bxl-typescript bx-lg'></i>;
  const javascript = <i className='bx bxl-javascript bx-lg'></i>;
  const ThePathTitle = 'The Path';
  const TeamCamaTitle = 'Team Cama';
  const PokeMonTitle = 'Poki Web App';
  const csharp = <SiCsharp size='40'/>;
  const mySQL = <SiMysql  size='50'/>;

  return (
    <div id='projects' className='projectPage ProjectBG' style={{paddingTop: '75px'}}>
        <div className='text-center'>
        <h1  className='heading'><span>Projects</span></h1>
        </div>
        <Container style={{marginTop: '100px'}}>
          <Row>
          <Col lg={4} md={12} sm={12} className='my-center'><NewProjectCard title={thePath} Csharp={csharp} SQL={mySQL} Description={thePathDesc} picture={thepathpic} Site={thePathSite} IconOne={Bootstrap} IconTwo={typescript} IconThree={react} BoxTitle={ThePathTitle}/></Col>
          <Col lg={4} md={6} sm={12} className='my-center'><NewProjectCard title={teamCama} Description={teamCamaDesc} picture={CamaPic} Site={teamCamaSite} IconOne={Bootstrap} IconTwo={javascript} IconThree={react} BoxTitle={TeamCamaTitle}/></Col>
          <Col lg={4} md={6} sm={12} className='my-center'><NewProjectCard title={PokiApi} Description={pokeDesc} picture={pokePic} Site={pokeSite} IconOne={Bootstrap} IconTwo={javascript} IconThree={react} BoxTitle={PokeMonTitle}/></Col>
                  </Row>
                  </Container>
        </div>
  )
}
