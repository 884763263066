import React from 'react'
import { Col, Row } from 'react-bootstrap'
export default function NewProjectCard(props) {
  const SQL = require('../assets/image.png');
  const CSharp = require('../assets/csharp.png');


    return (
        
            <Row>
                
                <Col lg={12}><Col lg={12}><h2 className='text-center abtText'>{props.BoxTitle}</h2></Col>
                    <div style={{position: 'relative'}} className='cardbox'>
                    <div className='CardContent'>
                        <div className='face face1'>
                            <p style={{marginTop: 50}}>{props.Description}
                            </p>
                            <a className="custom-bt" href={props.Site} target='_blank'>Go To Site</a>
                            <Row className='projecticons'>
                                <h2>Built With</h2>
                                <Col title='Bootstrap' className='mobileIcon'>{props.IconOne}</Col>
                                <Col title='JavaScript' className='mobileIcon'>{props.IconTwo}</Col>
                                <Col title='React' className='mobileIcon'>{props.IconThree}</Col>
                                <Col className='absoluteIcons' lg={6}md={6}sm={6}xs={6}>{props.Csharp}</Col>
                                <Col className='absoluteIcon' lg={6}md={6}sm={6}xs={6}>{props.SQL}</Col>
                            </Row>
                        </div>
                        <div className='face face2'>
                            <img src={props.picture} alt='Project Pictures' />
                        </div>

                    </div>
                </div></Col>

            </Row>
        
    )
}
