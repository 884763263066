import 'bootstrap/dist/css/bootstrap.min.css';
import './Components/styles.css'
import HomePage from './Components/HomePage';
import NavScrollExample from './Components/Navbar';
import './Components/Cardcss.css'
import 'boxicons/css/boxicons.min.css';
import ScrollButton from './Components/ScrollButton';
import './Components/ScrollButton.css'
import ProjectPage from './Components/ProjectPage';
import Footer from './Components/Footer';
import './Components/Footer.css'
import Sidebar from './Components/Sidebar';
import './Components/SideBar.css'
import './Components/Resume.css'
import './Components/aboutme.css'
function App() {
  return (
<div className='maingBG'>
  <ScrollButton/>

<NavScrollExample/>
<HomePage/>
<ProjectPage/>
<Footer/>
<Sidebar/>
</div>
  );
}

export default App;
